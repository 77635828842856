import round from "lodash.round";
import { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { useFetchTrophies } from "../../../hooks/useFetchTrophies";
import { entityPhotoErrorHandler } from "../../../hooks/useImageURLFromPath";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { getTopCredits } from "../../../hooks/user";
import { applyPromoCode } from "../../../store/actions/marketing";
import { getMusoCredit } from "../../../store/actions/muso";
import { downloadReviewStats } from "../../../store/actions/stats";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PromoCode } from "../../../store/models/promoCode";
import {
  DiscountRate,
  RecordingService,
} from "../../../store/models/recording";
import { Studio, StudioRoom } from "../../../store/models/studio";
import { BadgeEnum, FullWidthBadges } from "../../../store/models/trophy";
import User from "../../../store/models/user";
import { getResizeURL } from "../../../store/utils";
import {
  getDisplayableNameForStudio,
  getDisplayableNameForUser,
  getIsUserAandR,
} from "../../../store/utils/entityUtils";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import { roundMinutesToNearestQuarterHour } from "../../../utils/utils";
import { Badge } from "../../elements/Badge/Badge";
import { MoneySign } from "../../elements/MoneySign/MoneySign";
import { ReviewAggregate } from "../../elements/ReviewAggregate/ReviewAggregate";
import { ColorPalette } from "../../theme";
import { FavoriteHeart } from "../FavoriteHeart/FavoriteHeart";
import { TrophyListItem } from "../TrophyRoomList/TrophyRoomList";
import "./ProfileCard.css";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";

export enum ProfileCardType {
  STUDIO,
  STUDIO_ROOM,
  ENGINEER,
}

export interface ProfileCardProps {
  id: number;
  type: ProfileCardType;
  engineerId?: number;
  entityPath: string;
  name: string;
  path: string;
  subheader: string;
  credits?: string;
  studioName?: string;
  hourRate?: number;
  minimumTime?: number;
  isStudioManager?: boolean;
  numberOfSign?: number;
  discountRate?: DiscountRate;
  discountedRate?: number;
  showDisciplines?: boolean;
  onClickAfterRedirect?: () => void;
}

export const ProfileCard = ({
  id,
  engineerId,
  type,
  entityPath,
  name,
  path,
  subheader,
  credits,
  isStudioManager = false,
  studioName,
  numberOfSign = 0,
  hourRate,
  minimumTime,
  discountRate,
  discountedRate,
  showDisciplines = true,
  onClickAfterRedirect,
}: ProfileCardProps) => {
  const [currentRating, setCurrentRating] = useState<number>(0);
  const [numberOfReviews, setReviewCount] = useState<number>(0);
  const [musoCredits, setMusoCredits] = useState<string>("");
  const [loadingCredits, setLoadingCredits] = useState(false);

  const reviewId = useMemo(() => {
    if (type === ProfileCardType.ENGINEER) {
      return { engineer_id: engineerId, page: 1 };
    }

    if (type === ProfileCardType.STUDIO) {
      return { studio_id: id, page: 1 };
    }

    return { studio_room_id: id, page: 1 };
  }, [id, type, engineerId]);

  const trophyId = useMemo(() => {
    if (type === ProfileCardType.ENGINEER) {
      return { userId: id };
    }

    if (type === ProfileCardType.STUDIO) {
      return { studioId: id };
    }

    return { studioRoomId: id };
  }, [type, id]);

  const stats = useAppSelector((state) => {
    if (type === ProfileCardType.ENGINEER) {
      return state.statService.engineerReviews[engineerId ?? -1];
    }

    if (type === ProfileCardType.STUDIO_ROOM) {
      return state.statService.studioRoomReviews[id ?? -1];
    }

    return state.statService.studioReviews[id ?? -1];
  });

  const dispatch = useAppDispatch();
  const [minimumHourToEnableRates, setMinimumHourToEnableRates] = useState<
    number | undefined
  >(undefined);
  const { isDesktop } = useMediaQueryBreakpoint();

  // Fetch muso credit information for the passed in engineer.
  useEffect(() => {
    if (type !== ProfileCardType.ENGINEER) {
      return;
    }
    setLoadingCredits(true);
    dispatch(
      getMusoCredit({
        user_id: id,
      }),
    )
      .unwrap()
      .then((res) => {
        const verifiedSet: Set<string> = getTopCredits(res);
        setMusoCredits(Array.from(verifiedSet).join(", "));
      })
      .catch(() => {})
      .finally(() => {
        setLoadingCredits(false);
      });
  }, [dispatch, id, type]);

  useEffect(() => {
    if (stats !== undefined) {
      const { average_rating, ratings_count } = stats;
      setCurrentRating(average_rating);
      setReviewCount(ratings_count);
    } else {
      dispatch(downloadReviewStats(reviewId))
        .unwrap()
        .then((data) => {
          const { average_rating, ratings_count } = data;
          setCurrentRating(average_rating);
          setReviewCount(ratings_count);
        })
        .catch(() => {});
    }
  }, [dispatch, reviewId, stats]);

  useEffect(() => {
    if (discountRate === undefined) {
      return setMinimumHourToEnableRates(undefined);
    }
    const hours = discountRate.minimum_time_to_enable_rate / 60;
    setMinimumHourToEnableRates(hours);
  }, [discountRate]);

  const { userTrophies, studioRoomTrophies, studioTrophies } =
    useFetchTrophies(trophyId);
  const trophies =
    (studioTrophies ?? userTrophies ?? studioRoomTrophies)?.filter((trophy) => {
      return !FullWidthBadges.some((fullWidthBadge) =>
        Boolean(fullWidthBadge === trophy.badgeEnum),
      );
    }) ?? [];

  const modifiedSubHeader =
    type === ProfileCardType.ENGINEER ? "@" + subheader : subheader;
  const disciplines =
    type === ProfileCardType.ENGINEER && isStudioManager
      ? "Engineer • Studio Manager"
      : type === ProfileCardType.ENGINEER
        ? "Engineer"
        : isStudioManager
          ? "Studio Manager"
          : type === ProfileCardType.STUDIO
            ? "Facility"
            : "Studio Room";

  const isStudioOrStudioRoomCard = type !== ProfileCardType.ENGINEER;

  const handleCallback = useCallback(() => {
    if (onClickAfterRedirect) {
      onClickAfterRedirect();
    }
    emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.USER_CLICK_PROFILE_CARD, {
      profileId: id,
      engineerId,
      costRating: numberOfSign,
      currentRating,
      numberOfReviews,
      profilePath: path,
      profileType: type,
    });
  }, [
    onClickAfterRedirect,
    id,
    engineerId,
    numberOfSign,
    numberOfReviews,
    currentRating,
    path,
    type,
  ]);
  return (
    <Link
      to={path}
      onClick={handleCallback}
      className="trending-profile-link-container"
    >
      <div className="trending-profile-card-container ">
        <div className="trending-profile-image-icon-container">
          {numberOfSign > 0 && (
            <div className="trending-dollar-sign-container">
              <MoneySign numberOfSign={numberOfSign} />
            </div>
          )}
          {Boolean(numberOfReviews) && (
            <ReviewAggregate
              reviews={numberOfReviews}
              average={currentRating}
            />
          )}
          {trophies.find(
            (trophy) =>
              trophy.badgeEnum === BadgeEnum.TRENDING &&
              trophy.isCompleted === true,
          ) && <Badge badgeEnum={BadgeEnum.TRENDING} />}
          <span className="trending-favorite-container">
            <FavoriteHeart
              liked_studio_room={
                type === ProfileCardType.STUDIO_ROOM ? id : undefined
              }
              liked_user={type === ProfileCardType.ENGINEER ? id : undefined}
              liked_studio={type === ProfileCardType.STUDIO ? id : undefined}
            />
          </span>
        </div>
        <img
          src={getResizeURL(isStudioOrStudioRoomCard, entityPath)}
          className="trending-profile-image-container"
          onError={(e) => entityPhotoErrorHandler(e, entityPath)}
        />
        <div className="trending-profile-info-container trending-profile-name">
          {isStudioOrStudioRoomCard ? (
            <>
              <p className={"h7-semi-bold"}>
                {name}
                <span className="h7 trending-profile-name">
                  {" | "}
                  {studioName ?? "Recording Studio"}
                </span>
              </p>
              <p className="b1 trending-profile-location-text">{subheader}</p>
            </>
          ) : (
            <>
              <p className="h7-semi-bold trending-profile-name">{name}</p>
              <p className="b2 ">{modifiedSubHeader}</p>
            </>
          )}
          <div className="trending-profile-badge-container">
            {trophies?.map((trophy, index) => {
              if (trophy.badgeEnum === BadgeEnum.TRENDING) {
                return null;
              }
              return (
                <TrophyListItem
                  key={index}
                  trophy={trophy}
                  idx={index}
                  hideText
                  clipsProjectCompleted
                />
              );
            })}
            {showDisciplines && (
              <span className="b3 trending-discipline-text-container">
                {disciplines}
              </span>
            )}
          </div>
          <div className="trending-profile-rate-container">
            {hourRate && !discountedRate && (
              <p className={"b1-semi-bold "}>
                {DollarFormatter().format(hourRate)}
                {!isStudioOrStudioRoomCard && "+"}
                {
                  <span className={"b1 mx-1"}>
                    {isStudioOrStudioRoomCard ? "per hour" : ""}
                  </span>
                }
              </p>
            )}
            {hourRate &&
              discountRate &&
              minimumHourToEnableRates &&
              !discountedRate && (
                <p
                  style={{ marginRight: "5px" }}
                  className="b3 studio-room-card-minimum-time"
                >
                  {`${DollarFormatter().format(
                    Number(discountRate.service_rate.price) *
                      minimumHourToEnableRates,
                  )} for ${minimumHourToEnableRates} hours (${round(((hourRate - Number(discountRate.service_rate.price)) / hourRate) * 100, 2)}% off)`}
                </p>
              )}
            {" \t"}
            {hourRate && discountedRate && (
              <p
                className={"b1-semi-bold "}
                style={{
                  textDecoration: "line-through",
                  color: ColorPalette.LightGray,
                }}
              >
                {DollarFormatter().format(hourRate)}
              </p>
            )}
            {discountedRate && Boolean(applyPromoCode) && (
              <p className={"b1-semi-bold mx-1"}>
                {DollarFormatter().format(discountedRate)}
                {<span className={"b1 mx-1"}>{"per hour"}</span>}
              </p>
            )}
            {!discountRate && minimumTime && (
              <p className="b3 studio-room-card-minimum-time">
                {roundMinutesToNearestQuarterHour(minimumTime)} hour minimum
              </p>
            )}
          </div>
          {!isStudioOrStudioRoomCard && (
            <p className="b2 trending-profile-credits">
              {loadingCredits ? (
                <Skeleton
                  width={"30%"}
                  count={1}
                  height={!isDesktop ? 5 : 10}
                />
              ) : musoCredits ? (
                musoCredits
              ) : (
                musoCredits ?? credits
              )}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};

export const getStudioRoomProfileCard = (
  studioRoom: StudioRoom,
  index: number,
  path: string,
  appliedPromoCode?: PromoCode,
  showDisciplines = true,
) => {
  const image: string = studioRoom.photo?.path ?? "";
  const name: string = studioRoom.room_name;
  const location: string = studioRoom.studio?.location?.city_location ?? "";
  const studioUsername = studioRoom.studio?.username ?? "";
  const studioDisplayName: string = studioRoom.studio
    ? getDisplayableNameForStudio(studioRoom.studio)
    : studioUsername;
  const recording_service: RecordingService | null =
    studioRoom.recording_service ?? null;
  const hourRate = recording_service
    ? recording_service?.service_rate.price
    : undefined;
  const minimumTime = recording_service
    ? recording_service?.minimum_session_time_minutes
    : undefined;
  const discountRate =
    recording_service?.recording_service_discount_rate !== undefined
      ? recording_service?.recording_service_discount_rate[0]
      : undefined;

  const discountedRate = () => {
    if (!appliedPromoCode) return undefined;
    if (!hourRate) return undefined;
    if (studioRoom?.studio?.campaigns_opted_in) {
      return hourRate * (1 - appliedPromoCode.discount_percentage);
    }
    return undefined;
  };

  return (
    <ProfileCard
      key={`${studioRoom.id}-${index}}`}
      type={ProfileCardType.STUDIO_ROOM}
      path={path}
      id={studioRoom.id}
      subheader={location}
      entityPath={image}
      name={name}
      studioName={studioDisplayName}
      hourRate={hourRate}
      minimumTime={minimumTime}
      discountRate={discountRate}
      showDisciplines={showDisciplines}
      discountedRate={discountedRate()}
    />
  );
};

export const getStudioProfileCard = (
  studio: Studio,
  path: string,
  showDisciplines = true,
) => {
  const image: string = studio.photo?.path ?? "";
  const location: string = studio?.location?.city_location ?? "";
  const studioUsername = studio?.username ?? "";
  const studioDisplayName: string =
    getDisplayableNameForStudio(studio) || studioUsername;
  const recording_service: RecordingService | null =
    studio.recording_service ?? null;
  const hourRate = recording_service?.service_rate.price;
  const minimumTime = recording_service?.minimum_session_time_minutes;
  return (
    <ProfileCard
      key={studio.id}
      id={studio.id}
      type={ProfileCardType.STUDIO}
      path={path}
      subheader={location}
      entityPath={image}
      name={studioDisplayName}
      hourRate={hourRate ? hourRate : undefined}
      minimumTime={minimumTime}
      showDisciplines={showDisciplines}
    />
  );
};

export const getUserProfileCard = (
  user: User,
  index: number,
  path: string,
  onClickAfterRedirect?: () => void,
) => {
  const image = user.photo?.path ?? "";
  const name = getDisplayableNameForUser(user);
  const isStudioManager = getIsUserAandR(user);
  const numberOfSign = user.dollar_signs ?? 0;
  return (
    <ProfileCard
      key={`${user.id}-${index}`}
      path={path}
      id={user.id}
      type={ProfileCardType.ENGINEER}
      engineerId={user.engineer?.id}
      subheader={user.username}
      entityPath={image}
      name={name}
      hourRate={user.service_rate?.price}
      isStudioManager={isStudioManager}
      numberOfSign={numberOfSign}
      credits={user.profile?.bio ?? ""}
      onClickAfterRedirect={onClickAfterRedirect}
    />
  );
};
