export enum QUERY_KEYS {
  CREATE_TEAM = "create-team",
  CREATE_TEAM_INVITE = "create-team-invite",
  LOGIN = "login",
  LOGIN_UMG = "login-umg",
  LOGIN_GOOGLE = "login-google",
  SIGN_UP = "sign-up",
  CREATE_PROMO_CODE = "create-promo-code",
  FETCH_PAGINATED_SCHEDULED_PROJECTS = "fetch-paginated-scheduled-projects",
  FETCH_SCHEDULED_PROJECT_OVERVIEW = "fetch-scheduled-project-overview",
  GET_ITEMIZED_TRANSACTION = "get-itemized-transaction",
  GET_PAGINATED_ADMIN_ACTION_ITEMS = "get-paginated-admin-action-items",
  GET_SESSION_BOOKING_DETAILS = "get-session-booking-details",
  GET_SESSION_USER_ROLES = "get-session-user-roles",
  LOAD_USER = "load-user",
  LOAD_STUDIOS = "load-studios",
}
