export const UNKNOWN_ERROR = -1; // Default error code if no other code is specified.
export const AUTH_FAILED = -2;
export const REQUIRED_FIELD_MISSING = -3;
export const INTERNAL_SERVER_ERROR = -4;
export const METHOD_NOT_ALLOWED = -5;
export const PARSE_ERROR = -6;
export const USER_PHOTO_NOT_FOUND = -7;
export const USER_NOT_FOUND = -8;
export const NOT_AN_ENGINEER = -9;
export const PHONE_NUMBER_NOT_FOUND = -10;
export const BAD_VERIFICATION_CODE = -11;
export const USER_NOT_ARTIST = -12;
export const USER_NOT_ENGINEER = -13;
export const ENGINEER_NOT_FOUND = -14;
export const FILES_TO_MIX_NOT_FOUND = -15;
export const MIXED_FILES_NOT_FOUND = -16;
export const REFERENCE_TRACK_MISSING = -17;
export const NOT_ZIP_FILE = -18;
export const INVALID_SONG_FORMAT = -19;
export const TOO_MANY_REFERENCE_TRACKS = -20;
export const USER_NOT_FOUND_OR_EMAIL_NOT_VERIFIED = -21;
export const REFERENCE_SONG_NOT_FOUND = -22;
export const MIXED_SONG_NOT_FOUND = -23;
export const NOT_REFERENCE_OR_STEMS = -24;
export const STEMS_MISSING = -25;
export const NOT_MAIN_OR_STEMS = -26;
export const MAIN_MIX_MISSING = -27;
export const NOT_WAV_FILE = -28;
export const MESSAGE_NOT_FOUND = -29;
export const NOT_YOUR_MESSAGE_THREAD = -30;
export const NO_MESSAGE_SENT_ERROR = -31;
export const MESSAGE_TOO_LONG = -32;
export const MIXING_PROJECT_MESSAGE_THREAD_NOT_FOUND = -33;
export const TOO_MANY_GENRES = -34;
export const NOT_ENOUGH_GENRES = -35;
export const ENGINEERING_SERVICE_UNIQUE_LINK_NOT_FOUND = -36;
export const MIXING_PROJECT_LISTEN_LINK_NOT_FOUND = -37;
export const MASTERING_PROJECT_NOT_FOUND = -38;
export const PROJECT_NOT_FOUND = -39;
export const FILE_TO_MASTER_NOT_FOUND = -40;
export const MAIN_MASTER_NOT_FOUND = -41;
export const MIXING_PROJECT_NOT_FOUND = -42;
export const USER_DOES_NOT_OWN_REFERRER_PROJECT = -43;
export const ALTS_MISSING = -44;
export const USER_NOT_A_AND_R = -45;
export const ENGINEER_PREFERENCES_NOT_FOUND = -46;
export const LISTEN_LINK_PASSWORD_PROTECTED = -47;
export const LISTEN_LINK_PASSWORD_INCORRECT = -48;
export const PROJECT_UPLOAD_LINK_NOT_FOUND = -49;
export const REQUESTED_DATE_NOT_AVAILABLE = -50;
export const SCHEDULED_PROJECT_NOT_FOUND = -51;
export const CHARGE_NOT_FOUND = -52;
export const CHARGE_NOT_SUCCESSFUL = -53;
export const EXISTING_USERNAME = -54;
export const EXISTING_EMAIL = -55;
export const SHARED_LINK_EXPIRED = -56;
export const PURCHASE_ORDER_DOES_NOT_EXIST = -57;
export const ENGINEER_NOT_DOLBY_ATMOS_CERTIFIED = -58;
export const ENGINEER_IS_NOT_A_STRIPE_CUSTOMER = -59;
export const MISSING_STRIPE_SUBSCRIPTION_PLAN = -60;
export const INVALID_STRIPE_SUBSCRIPTION_PLAN = -61;
export const ENGINEER_HAS_A_STRIPE_SUBSCRIPTION = -62;
export const COUNTRY_NOT_STRIPE_SUPPORTED = -63;
export const INVALID_MUSO_SEARCH_TYPE = -64;
export const MUSO_API_FAILED = -65;
export const MISSING_REQUEST_DATA = -66;
export const MUSO_ENTITY_NOT_FOUND = -67;
export const INVALID_MUSO_STATUS = -68;
export const MUSO_ENTITY_CLAIMED = -69;
export const INVALID_PARAMETER = -70;
export const UNAUTHORIZED_MUSO_UPDATE = -71;
export const ARTIST_NOT_FOUND = -72;
export const ACCESS_DENIED = -73;
export const PREDEFINED_PROJECT_NOT_FOUND = -74;
export const PREDEFINED_PROJECT_CLAIMED = -75;
export const PREDEFINED_PROJECT_LINK_EXPIRED = -76;
export const ENGINEER_SERVICE_NOT_FOUND = -77;
export const NOT_SUPERUSER = -78;
export const EMAIL_LOGIN_FAILED_TOO_MANY_ACCOUNTS = -79;
export const SCHEDULED_PROJECT_SHARE_LINK_NOT_FOUND = -80;
export const SHARE_LINK_PASSWORD_PROTECTED = -81;
export const SHARE_LINK_PASSWORD_INCORRECT = -82;
export const PASSWORD_LENGTH_NOT_MET = -83;
export const PASSWORD_MISSING_DIGIT = -84;
export const PASSWORD_MISSING_CAPITAL_ALPHA = -85;
export const PASSWORD_MISSING_SPECIAL_CHAR = -86;
export const FILE_NOT_FOUND = -87; // No error display needed.
export const FEATURED_TRACK_NOT_FOUND = -88;
export const FILE_NOT_PENDING = -89;
export const INVALID_USERNAME_ON_SIGNUP = -90; // Needed to display an error message when signing up fails serializer validation.
export const USER_NOT_ENGINEER_OR_ARTIST = -91;
export const FEATURED_TRACK_REQUEST_NOT_FOUND = -92;
export const INVALID_MIX_TO_MASTER_HANDOFF = -93;
export const AUDIO_FORMAT_NOT_ACCEPTED = -94;
export const SONG_ANALYSIS_FAILED = -95;
export const MESSAGE_THREAD_CREATION_FAILED = -96;
export const DIRECT_MESSAGE_CHANNEL_NOT_FOUND = -97;
export const DIRECT_MESSAGE_CHANNEL_UPDATE_UNAUTHORIZED = -98;
export const CHANNEL_EXISTS = -99;
export const UNAUTHORIZED_FILE_VERSION_UPDATE = -100;
export const VALID_PROMO_CODE_NOT_FOUND = -101;
export const PROMO_CODE_ALREADY_USED = -102;
export const NEED_AT_LEAST_ONE_WORKDAY = -103;
export const REVISION_NOT_FOUND = -104;
export const TRANSACTION_NOT_FOUND = -105;
export const NO_REVISIONS_AVAILABLE = -106;
export const UNAUTHORIZED_PREDEFINED_SCHEDULED_PROJECT_UPDATE = -107;
export const GOOGLE_TOKEN_ERROR = -108;
export const INVALID_WORKING_HOURS = -109;
export const UNAUTHORIZED_REFUND = -110;
export const PAYMENT_METHOD_NOT_FOUND = -111;
export const PASSWORD_MISSING_LOWERCASE_ALPHA = -112;
export const INVALID_FILTER_TYPE = -113;
export const RECORDING_SERVICE_NOT_FOUND = -114;
export const PHONE_NUMBER_NOT_VERIFIED = -115;
export const WORKING_HOURS_NOT_FOUND = -116;
export const INVALID_DATE_RANGE = -117;
export const SESSION_LENGTH_NOT_SPECIFIED = -118;
export const RECORDING_LOCATION_NOT_SPECIFIED = -119;
export const RECORDING_SESSION_BOOKING_NOT_FOUND = -120;
export const PROJECT_ACCEPTANCE_NOT_FOUND = -121;
export const ERROR_SETTING_UP_RECORDING_SERVICE = -122;
export const MESSAGE_REQUEST_LIMIT_REACHED = -125;
export const ENGINEER_NOT_VERIFIED_OR_ONBOARDED = -126;
export const STUDIO_NOT_FOUND = -127;
export const STUDIO_USERNAME_ALREADY_TAKEN = -128;
export const STUDIO_ROOM_NOT_FOUND = -129;
export const AMENITY_UPDATE_FAILED = -130;
export const INVALID_DATA = -131;
export const ENTITY_PHOTO_NOT_FOUND = -132;
export const INSUFFICIENT_PERMISSIONS = -133;
export const STUDIO_LOCATION_NOT_FOUND = -134;
export const ADMIN_EMAIL_REQUIRED = -135;
export const INVALID_STUDIO_NAME = -136;
export const TOO_MANY_SMS_ATTEMPTS = -137;
export const EMAIL_NOT_VERIFIED = -138;
export const DISCORD_USER_NOT_IN_EE_GUILD = -139;
export const DISCORD_CREDENTIALS_INVALID = -140;
export const STUDIO_AFFILIATION_INVITE_NOT_FOUND = -141;
export const STUDIO_AFFILIATION_INVITE_ALREADY_EXISTS = -142;
export const STUDIO_AFFILIATION_TYPE_NOT_FOUND = -143;
export const USER_ALREADY_STUDIO_TEAM_MEMBER = -144;
export const STUDIO_TEAM_MEMBER_NOT_FOUND = -145;
export const STUDIO_MANAGER_NOT_FOUND = -146;
export const TRANSACTION_UNIQUE_LINK_NOT_FOUND = -147;
export const TRANSACTION_UNIQUE_LINK_ALREADY_USED = -148;
export const NOTIFICATION_NOT_FOUND = -149;
export const SERVICE_TYPE_NOT_SUPPORTED = -150;
export const NOT_STUDIO_MANAGER = -151;
export const CAN_NOT_HOST_RECORDING_SERVICES = -152;
export const NAVIGATOR_NOT_ENABLED = -153;
export const USER_ALREADY_HAS_A_STUDIO = -154;
export const RECORDING_SESSION_REQUIRES_ENGINEER = -157;
export const ERROR_FETCHING_ENGINEER_AVAILABILITY = -158;
export const ERROR_FETCHING_STUDIO_AVAILABILITY = -159;
export const USER_NOT_ENGINEER_OR_STUDIO_MANAGER = -160;
export const STUDIO_ROOM_AVAILABILTY_NOT_FOUND = -161;
export const CALENDAR_EMAIL_MISMATCH = -162;
export const OVERWRITE_PRICE_BELOW_ENGINEER_RATES = -163;
export const STUDIO_PROFILE_FOUND = -164;
export const SESSION_MODIFICATION_NOT_FOUND = -165;
export const ENGINEER_CALENDAR_NOT_CONFIGURED = -166;
export const STUDIO_ROOM_CALENDAR_NOT_CONFIGURED = -167;
export const STUDIO_REQUIRES_ENGINEER_BUT_NONE_AVAILABLE = -168;
export const STUDIO_REQUIRES_LOCATION = -169;
export const EMAIL_LEAD_NOT_FOUND = -170;
export const SOT_VOTE_ALREADY_COUNTED = -171;
export const OVERWRITE_PRICE_BELOW_STUDIO_RATES = -172;
export const REQUIRED_CLEAN_ALT_MISSING = -173;
export const PLUS_CHARACTER_NOT_ALLOWED_IN_EMAIL = -174;
export const PROJECT_BUDGET_REJECTED = -175;
export const PROJECT_REVIEW_COMMENT_WINDOW_EXPIRED = -176;
export const FILE_UPLOAD_FAILED = -177;
export const USER_IS_NOT_A_STRIPE_CUSTOMER = -178;
export const INVOICE_PAYMENT_PLAN_NOT_SUPPORTED = -179;
export const TEAM_NOT_FOUND = -180;
export const AANDR_ALREADY_TEAM_MEMBER = -181;
export const ADMIN_ALREADY_TEAM_MEMBER = -182;
export const USER_NOT_ADMIN_ACCOUNT_TYPE = -183;
export const TEAM_MEMBER_NOT_FOUND = -184;
export const AFFILIATION_INVITE_NOT_FOUND = -185;
export const FILES_DOWNLOAD_INELIGIBLE = -186;
export const FILES_DOWNLOAD_FAILED = -187;
export const TRACK_MP3_GENERATION_INELIGIBLE = -188;
export const GENERATED_MP3_TRACK_NOT_FOUND = -189;
export const INVALID_INVOICE_ID = -190;
export const ADMIN_IS_NOT_TEAM_MANAGER = -191;
export const ERROR_CREATING_INVOICE_PAYMENT_PLAN = -192;
export const INVALID_MINIMUM_DEPOSIT_PERCENTAGE = -193;
export const PRICE_BELOW_PLATFORM_MINIMUM = -194;
export const TRANSACTION_INELIGIBLE_FOR_UPDATE = -195;
export const CALENDAR_ACCESS_NOT_PROVIDED = -196;
export const INVALID_GEOLOCATION_PROVIDED = -198;
export const INVALID_MAXIMUM_NUMBER_OF_STUDIO_ROOM_GUESTS = -199;
export const DELETE_PROJECT_NOT_ALLOWED = -200;
export const TOTAL_PRICE_NOT_SET = -201;
export const PROMO_CODE_NOT_APPLICABLE_WITH_DISCOUNT_LINK = -202;
export const PROMO_CODE_NOT_APPLICABLE_RECORDING = -203;
export const THROTTLED = -204;
export const MUSO_AUTH_INFORMATION_NOT_FOUND = -205;
export const MUSO_AUTH_USER_ID_MISMATCH = -206;
export const MUSO_AUTH_PROFILE_ID_UNCLAIMED = -207;
export const INVALID_MUSO_API_AUTHENTICATION = -208;
export const INVALID_SCHEDULED_PROJECT_STAGE = -209;
export const INVALID_TRACK_STAGE = -210;
export const INVALID_SESSION_STAGE = -211;
export const INVALID_FILE_LINK = -212;
export const NO_FILE_ASSOCIATED_WITH_THE_PROJECT = -215;
export const VIDEO_UPLOAD_ERROR = -216;
export const VIDEO_INCORRECT_FILE_TYPE = -217;
export const VIDEO_EXCEEDS_MAX_SIZE = -218;
export const MEDIA_CONVERT_ERROR = -219;
export const SESSION_MODIFICATION_NOT_ALLOWED = -220;
export const USER_NOT_ON_PROJECT = -221;
export const RECORDING_SESSION_SHARE_LINK_NOT_FOUND = -222;
export const TRANSITION_NOT_ALLOWED = -223;
export const INVALID_PAYMENT_INTENT_CLIENT_SECRET = -227;
export const ENGINEER_NOT_ON_ADMIN_TEAM = -228;
export const STUDIO_NOT_ON_ADMIN_TEAM = -229;
export const ENGINEER_RATES_EXCEED_ORIGINAL_PRICE = -230;
export const BUDGET_MANAGER_NOT_FOUND = -231;
export const FAVORITED_ACCOUNT_NOT_FOUND = -232;

export const SUPPLY_CHAIN_ACCESS_DENIED = -234;

export const exceptionCodeErrorStringMap = new Map<number, string>([
  [UNKNOWN_ERROR, "Something went wrong. Unknown error."],
  [AUTH_FAILED, ""],
  [REQUIRED_FIELD_MISSING, "Required field missing."],
  [INTERNAL_SERVER_ERROR, "Something went wrong on our end. Sorry about that."],
  [METHOD_NOT_ALLOWED, "Not allowed to complete action."],
  [PARSE_ERROR, "Failed to parse the information."],
  [USER_PHOTO_NOT_FOUND, ""], // Removed error message so that this one doesn't show.

  [USER_NOT_FOUND, "User account not found."],
  [
    NOT_AN_ENGINEER,
    "User is not an Engineer. Login as Engineer or register an Engineer account",
  ],

  [USER_NOT_ARTIST, "Please change account type to Artist to proceed."],
  [MIXING_PROJECT_NOT_FOUND, "Mixing project not found."],
  [ENGINEER_NOT_FOUND, "Failed to load valid Engineer."],
  [FILES_TO_MIX_NOT_FOUND, "Failed to load files to mix."],
  [MIXED_FILES_NOT_FOUND, "Mixed files not found."],
  [USER_NOT_ENGINEER, "User is not an engineer."],
  [PHONE_NUMBER_NOT_FOUND, "Sorry], we don't have a phone number on file."],
  [
    BAD_VERIFICATION_CODE,
    "Verification code is incorrect or has expired. Please try again or request a new verification code.",
  ],
  [
    REFERENCE_TRACK_MISSING,
    "Reference (demo) track is missing from uploaded files. Please make sure a .wav file is uploaded.",
  ],
  [NOT_ZIP_FILE, "The uploaded file is not a zip file."],
  [
    INVALID_SONG_FORMAT,
    "The format of one or more audio files is not permitted.",
  ],
  [TOO_MANY_REFERENCE_TRACKS, "Too many reference tracks found in upload."],
  [
    USER_NOT_FOUND_OR_EMAIL_NOT_VERIFIED,
    "User not found or email not verified.",
  ],
  [REFERENCE_SONG_NOT_FOUND, "Failed to download reference track."],
  [MIXED_SONG_NOT_FOUND, "Failed to download mixed track."],
  [
    NOT_REFERENCE_OR_STEMS,
    "Upload does not contain 'reference' or 'stems' in the filename.",
  ],
  [STEMS_MISSING, "Stems missing from uploaded files."],
  [
    NOT_MAIN_OR_STEMS,
    "Upload does not contain 'main' or 'stems' in the filename.",
  ],
  [MAIN_MIX_MISSING, "Main exported mix missing from uploaded files."],
  [NOT_WAV_FILE, "The uploaded file is not a .wav file."],

  [MESSAGE_NOT_FOUND, "Couldn't load message."],
  [
    NOT_YOUR_MESSAGE_THREAD,
    "Couldn't send message to thread because you are not part of it.",
  ],
  [NO_MESSAGE_SENT_ERROR, "Error: message was unable to send."],
  [
    MESSAGE_TOO_LONG,
    "Message too long to send], please send in smaller sections.",
  ],
  [
    MIXING_PROJECT_MESSAGE_THREAD_NOT_FOUND,
    "Error: message thread was not found.",
  ],
  [
    TOO_MANY_GENRES,
    "You are trying to select more than the maximum allowed genres.",
  ],
  [NOT_ENOUGH_GENRES, "Not enough genres selected. Select at least one genre"],
  [
    ENGINEERING_SERVICE_UNIQUE_LINK_NOT_FOUND,
    "Invalid link. Engineering service unique link not found",
  ],
  [
    MIXING_PROJECT_LISTEN_LINK_NOT_FOUND,
    "Invalid link. Mixing project listen link not found",
  ],
  [MASTERING_PROJECT_NOT_FOUND, "Mastering project not found."],
  [PROJECT_NOT_FOUND, "Valid project not found."],
  [FILE_TO_MASTER_NOT_FOUND, "Uploaded file for mastering not found."],
  [MAIN_MASTER_NOT_FOUND, "Uploaded finished master not found."],
  [USER_DOES_NOT_OWN_REFERRER_PROJECT, "User does not own referrer project."],
  [ALTS_MISSING, "Alts missing from uploaded files."],

  [
    USER_NOT_A_AND_R,
    "User A&R profile not recognized, please change account type to proceed.",
  ],
  [ENGINEER_PREFERENCES_NOT_FOUND, "Could not find engineer preferences."],
  [LISTEN_LINK_PASSWORD_PROTECTED, "Listen link password protected."],
  [LISTEN_LINK_PASSWORD_INCORRECT, "Listen link password incorrect."],
  [PROJECT_UPLOAD_LINK_NOT_FOUND, "Project not found via share link."],
  [REQUESTED_DATE_NOT_AVAILABLE, "Requested date not available."],
  [SCHEDULED_PROJECT_NOT_FOUND, "Project not found."],
  [CHARGE_NOT_FOUND, "Successful charge not found."],
  [CHARGE_NOT_SUCCESSFUL, "Charge not successful."],
  [EXISTING_USERNAME, "Existing account with this username found."],
  [EXISTING_EMAIL, "Existing account with this email found."],
  [SHARED_LINK_EXPIRED, "Share link is expired."],
  [
    PURCHASE_ORDER_DOES_NOT_EXIST,
    "The Purchase Order could not be found, please contact support.",
  ],
  [
    ENGINEER_NOT_DOLBY_ATMOS_CERTIFIED,
    "Must be Dolby ATMOS certified to offer Dolby ATMOS mixing services.",
  ],
  [
    ENGINEER_IS_NOT_A_STRIPE_CUSTOMER,
    "Engineer is not a valid stripe customer. Please contact support.",
  ],
  [
    MISSING_STRIPE_SUBSCRIPTION_PLAN,
    "A subscription plan was not found. Please contact support.",
  ],
  [
    INVALID_STRIPE_SUBSCRIPTION_PLAN,
    "A valid subscription plan was not found. Please contact support.",
  ],
  [
    ENGINEER_HAS_A_STRIPE_SUBSCRIPTION,
    "You must first cancel your current subscription plan before downgrading to basic.",
  ],
  [
    COUNTRY_NOT_STRIPE_SUPPORTED,
    "Unfortunately, this country is not currently supported by Stripe. We will expand payment options in the future.",
  ],
  [
    EMAIL_LOGIN_FAILED_TOO_MANY_ACCOUNTS,
    "Unfortunately, we cannot log you into your account because you have multiple accounts associated with your email. Please login with your username.",
  ],
  [
    INVALID_MUSO_SEARCH_TYPE,
    "Muso search not supported for this type of profile.",
  ],
  [MUSO_API_FAILED, "Failed to get data from Muso."],
  [
    MISSING_REQUEST_DATA,
    "Something went wrong. Please contact support for help.",
  ],
  [MUSO_ENTITY_NOT_FOUND, "Failed to find profile on Muso."],
  [INVALID_MUSO_STATUS, "Invalid Muso entity status. Please contact support."],
  [MUSO_ENTITY_CLAIMED, "Muso profile already claimed by another user."],
  [INVALID_PARAMETER, "Invalid parameter provided. Please contact support."],
  [UNAUTHORIZED_MUSO_UPDATE, "Unauthorized update to Muso update."],
  [ARTIST_NOT_FOUND, "Failed to load a valid Artist."],
  [ACCESS_DENIED, "Access denied."],
  [NOT_SUPERUSER, "User does not have superuser permissions."],
  [PREDEFINED_PROJECT_NOT_FOUND, "Could not find the package via the link."],
  [
    PREDEFINED_PROJECT_CLAIMED,
    "Please book the engineer via their profile page, or have the engineer set up an additional package for you. ",
  ],
  [
    PREDEFINED_PROJECT_LINK_EXPIRED,
    "This link is expired. Please have the engineer create a new package for you, or book on their profile.",
  ],
  [
    ENGINEER_SERVICE_NOT_FOUND,
    "Could not find the service provided by the engineer.",
  ],
  [
    SCHEDULED_PROJECT_SHARE_LINK_NOT_FOUND,
    "Project share link invalid or expired.",
  ],
  [
    SHARE_LINK_PASSWORD_PROTECTED,
    "Project overview page is password protected.",
  ],
  [
    SHARE_LINK_PASSWORD_INCORRECT,
    "Provided password to access the project overview page is incorrect.",
  ],
  [
    PASSWORD_LENGTH_NOT_MET,
    "Your password needs to be at least 8 characters long.",
  ],
  [
    PASSWORD_MISSING_DIGIT,
    "Your password needs to contain at least one number.",
  ],
  [
    PASSWORD_MISSING_CAPITAL_ALPHA,
    "Your password needs to contain at least one capital letter.",
  ],
  [
    PASSWORD_MISSING_SPECIAL_CHAR,
    "Your password needs to contain at least one special character.",
  ],
  [
    FEATURED_TRACK_NOT_FOUND,
    "Unable to load featured track. Please contact support.",
  ],
  [
    FILE_NOT_PENDING,
    "Something went wrong with file upload. Please contact support and reference this message.",
  ],
  [INVALID_USERNAME_ON_SIGNUP, "Invalid username provided."],
  [
    USER_NOT_ENGINEER_OR_ARTIST,
    "User account is not an engineer or artist type.",
  ],
  [
    FEATURED_TRACK_REQUEST_NOT_FOUND,
    "Could not find a valid featured track request.",
  ],
  [INVALID_MIX_TO_MASTER_HANDOFF, "Invalid project. Please contact support."],
  [AUDIO_FORMAT_NOT_ACCEPTED, "Audio format not accepted."],
  [
    SONG_ANALYSIS_FAILED,
    "Something went wrong analyzing your song. Please try again.",
  ],
  [
    MESSAGE_THREAD_CREATION_FAILED,
    "Failed to create a new message thread. Please contact support.",
  ],
  [DIRECT_MESSAGE_CHANNEL_NOT_FOUND, "Message thread not found."],
  [
    DIRECT_MESSAGE_CHANNEL_UPDATE_UNAUTHORIZED,
    "Updating this message thread is not authorized for this account.",
  ],
  [CHANNEL_EXISTS, "Message thread already exists."],
  [UNAUTHORIZED_FILE_VERSION_UPDATE, "File update not authorized."],
  [VALID_PROMO_CODE_NOT_FOUND, "Promo code is invalid."],
  [PROMO_CODE_ALREADY_USED, "Promo code has already been used."],
  [
    NEED_AT_LEAST_ONE_WORKDAY,
    "You must select at least one workday when setting up a service.",
  ],
  [TRANSACTION_NOT_FOUND, "Transaction expired or not retrievable."],
  [
    NO_REVISIONS_AVAILABLE,
    "No revisions available. Please purchase an additional revision to continue.",
  ],
  [
    UNAUTHORIZED_PREDEFINED_SCHEDULED_PROJECT_UPDATE,
    "Not authorized to update this scheduled project.",
  ],
  [GOOGLE_TOKEN_ERROR, "Google token error."],
  [
    INVALID_WORKING_HOURS,
    "Invalid working hours provided. Please contact support.",
  ],
  [UNAUTHORIZED_REFUND, "Unauthorized refund."],
  [PAYMENT_METHOD_NOT_FOUND, "Invalid payment method. Please contact support."],
  [
    PASSWORD_MISSING_LOWERCASE_ALPHA,
    "Your password needs to contain at least one lowercase letter.",
  ],
  [INVALID_FILTER_TYPE, "Invalid filter type. Please contact support."],
  [RECORDING_SERVICE_NOT_FOUND, "Recording service not found."],
  [PHONE_NUMBER_NOT_VERIFIED, "Phone number not verified."],
  [
    WORKING_HOURS_NOT_FOUND,
    "Working hours not found. Please re-setup the recording service.",
  ],
  [INVALID_DATE_RANGE, "Inavalid range of dates."],
  [SESSION_LENGTH_NOT_SPECIFIED, "No session length was specified."],
  [RECORDING_LOCATION_NOT_SPECIFIED, "No recording location was specified."],
  [RECORDING_SESSION_BOOKING_NOT_FOUND, "Studio booking session not found."],
  [PROJECT_ACCEPTANCE_NOT_FOUND, "Recording session booking not available."],
  [
    ERROR_SETTING_UP_RECORDING_SERVICE,
    "Unable to set up recording service. Please contact support.",
  ],
  [
    MESSAGE_REQUEST_LIMIT_REACHED,
    "You have reached the limit for sending message requests.",
  ],
  [
    ENGINEER_NOT_VERIFIED_OR_ONBOARDED,
    "As an engineer you need to be either onboarded or verified to perform this action.",
  ],
  [STUDIO_NOT_FOUND, "Studio profile not found."],
  [STUDIO_USERNAME_ALREADY_TAKEN, "This username is already taken."],
  [STUDIO_ROOM_NOT_FOUND, "Studio room profile not found."],
  [
    AMENITY_UPDATE_FAILED,
    "Something went wrong updating amenities. Please contact support.",
  ],
  [INVALID_DATA, "Invalid data."],
  [ENTITY_PHOTO_NOT_FOUND, "Photo not found."],
  [INSUFFICIENT_PERMISSIONS, "Insufficient permissions."],
  [
    STUDIO_LOCATION_NOT_FOUND,
    "Could not find a location associated with your studio. Please contact support.",
  ],
  [ADMIN_EMAIL_REQUIRED, "An admin email must be provided."],
  [
    INVALID_STUDIO_NAME,
    "Invalid studio username. Please use only letters, numbers, underscores or dashes.",
  ],
  [TOO_MANY_SMS_ATTEMPTS, "SMS code limit reached. Try again later."],
  [
    EMAIL_NOT_VERIFIED,
    "Email not yet verified. Please click the verification link in your email in order to proceed.",
  ],
  [DISCORD_USER_NOT_IN_EE_GUILD, "User is not a member of the Discord."],
  [
    DISCORD_CREDENTIALS_INVALID,
    "Your Discord credentials are invalid. Please link your Discord account again.",
  ],
  [STUDIO_AFFILIATION_INVITE_NOT_FOUND, "Invite not found."],
  [
    STUDIO_AFFILIATION_INVITE_ALREADY_EXISTS,
    "Invite already exists for that user and role.",
  ],
  [STUDIO_AFFILIATION_TYPE_NOT_FOUND, "Unknown role selected."],
  [
    USER_ALREADY_STUDIO_TEAM_MEMBER,
    "User is already a team member for that role.",
  ],
  [STUDIO_TEAM_MEMBER_NOT_FOUND, "Team member not found."],
  [STUDIO_MANAGER_NOT_FOUND, "Studio manager not found."],
  [TRANSACTION_UNIQUE_LINK_NOT_FOUND, "Transaction link not found."],
  [TRANSACTION_UNIQUE_LINK_ALREADY_USED, "Transaction link already used."],
  [NOTIFICATION_NOT_FOUND, "Notification not found."],
  [USER_ALREADY_HAS_A_STUDIO, "Studio manager already has a studio."],
  [SERVICE_TYPE_NOT_SUPPORTED, "Service type not supported."],
  [
    NOT_STUDIO_MANAGER,
    "User is not authorized to be a studio manager. Please contact support.",
  ],
  [
    CAN_NOT_HOST_RECORDING_SERVICES,
    "User is not authorized to host recording services. Please contact support.",
  ],
  [
    NAVIGATOR_NOT_ENABLED,
    "Location services required. Enable location services and reload this page.",
  ],
  [
    RECORDING_SESSION_REQUIRES_ENGINEER,
    "Recording sessions must be booked with an engineer at this studio.",
  ],
  [
    ERROR_FETCHING_ENGINEER_AVAILABILITY,
    "Something went wrong fetching engineer availability. Please contact support.",
  ],
  [
    ERROR_FETCHING_STUDIO_AVAILABILITY,
    "Something went wrong fetching studio availability. Please contact support.",
  ],
  [
    USER_NOT_ENGINEER_OR_STUDIO_MANAGER,
    "User is not an engineer or studio manager. Please contact support.",
  ],
  [
    STUDIO_ROOM_AVAILABILTY_NOT_FOUND,
    "Studio room availability not found. The studio manager may need to re-setup the studio room.",
  ],
  [
    CALENDAR_EMAIL_MISMATCH,
    "The email address associated with your calendar does not match the email address associated with your account. Update your primary account email on the Settings page in order to link this google calendar.",
  ],
  [
    OVERWRITE_PRICE_BELOW_ENGINEER_RATES,
    "Overridden price is below the selected engineer rates.",
  ],
  [STUDIO_PROFILE_FOUND, ""],
  [SESSION_MODIFICATION_NOT_FOUND, "Pending session request not found."],
  [
    ENGINEER_CALENDAR_NOT_CONFIGURED,
    "Engineer calendar not properly configured. Please direct message the engineer to inquire about booking.",
  ],
  [
    STUDIO_ROOM_CALENDAR_NOT_CONFIGURED,
    "Studio room calendar not properly configured. Please direct message the Studio Manager to inquire about booking.",
  ],
  [
    STUDIO_REQUIRES_ENGINEER_BUT_NONE_AVAILABLE,
    "This Studio requires an engineer for your session but none are available. Please direct message the Studio Manager to inquire about booking.",
  ],
  [STUDIO_REQUIRES_LOCATION, "Studio requires a location to be set."],
  [
    EMAIL_LEAD_NOT_FOUND,
    "Could not verify vote. Original vote not found. Please try again.",
  ],
  [
    SOT_VOTE_ALREADY_COUNTED,
    "Vote already counted. You cannot vote twice for the same song.",
  ],
  [
    OVERWRITE_PRICE_BELOW_STUDIO_RATES,
    "Overridden price is below the selected studio rates.",
  ],
  [
    REQUIRED_CLEAN_ALT_MISSING,
    "A clean alt in .wav format is required before you can transition this project into the next step. Upload the clean .wav and select clean from the drop down above the file uploader to submit.",
  ],
  [
    PLUS_CHARACTER_NOT_ALLOWED_IN_EMAIL,
    "A plus sign character is not allowed in an email address.",
  ],
  [
    PROJECT_BUDGET_REJECTED,
    "Your project's budget was rejected by the client's specified budget approver so it cannot proceed at this time. Please contact EngineEars support to resolve this issue.",
  ],
  [
    PROJECT_REVIEW_COMMENT_WINDOW_EXPIRED,
    "The comment window for this project has expired.",
  ],
  [FILE_UPLOAD_FAILED, "The file upload failed, please try again."],
  [
    USER_IS_NOT_A_STRIPE_CUSTOMER,
    "Something went wrong creating invoice, please refresh and try again.",
  ],
  [
    INVOICE_PAYMENT_PLAN_NOT_SUPPORTED,
    "Partial payment plan not supported for this transaction.",
  ],
  [TEAM_NOT_FOUND, "Valid team not found."],
  [AANDR_ALREADY_TEAM_MEMBER, "User already a member of this team."],
  [ADMIN_ALREADY_TEAM_MEMBER, "User already a member of this team."],
  [USER_NOT_ADMIN_ACCOUNT_TYPE, "User is not an admin account type."],
  [TEAM_MEMBER_NOT_FOUND, "Team member not found."],
  [AFFILIATION_INVITE_NOT_FOUND, "Team affiliation invitation not found."],
  [FILES_DOWNLOAD_INELIGIBLE, "Unable to download files for this project."],
  [
    FILES_DOWNLOAD_FAILED,
    "Something went wrong downloading files. Please refresh and try again or contact support.",
  ],
  [TRACK_MP3_GENERATION_INELIGIBLE, ""], // Removed error message so that this one doesn't show.
  [GENERATED_MP3_TRACK_NOT_FOUND, ""], // Removed error message so that this one doesn't show.
  [INVALID_INVOICE_ID, "Valid invoice not found."],
  [ADMIN_IS_NOT_TEAM_MANAGER, "Admin is not team manager."],
  [
    ERROR_CREATING_INVOICE_PAYMENT_PLAN,
    "Something went wrong creating invoice payment plan. Please contact support.",
  ],
  [INVALID_MINIMUM_DEPOSIT_PERCENTAGE, "Invalid minimum deposit percentage."],
  [PRICE_BELOW_PLATFORM_MINIMUM, "Price is below the platform minimum."],
  [TRANSACTION_INELIGIBLE_FOR_UPDATE, "Transaction cannot be updated."],
  [
    CALENDAR_ACCESS_NOT_PROVIDED,
    "Access to all requested permissions from your Google calendar must be provided to utilize scheduling features.",
  ],
  [
    INVALID_GEOLOCATION_PROVIDED,
    "Invalid address. Please provide a valid address to proceed.",
  ],
  [
    INVALID_MAXIMUM_NUMBER_OF_STUDIO_ROOM_GUESTS,
    "Invalid maximum number of guests for studio room.",
  ],
  [
    DELETE_PROJECT_NOT_ALLOWED,
    "Only in-progress projects that you have created can be deleted.",
  ],
  [
    TOTAL_PRICE_NOT_SET,
    "Something went wrong calculating the total price. Please try again.",
  ],
  [
    PROMO_CODE_NOT_APPLICABLE_WITH_DISCOUNT_LINK,
    "Promo code cannot be applied to a booking created with a discount link.",
  ],
  [
    PROMO_CODE_NOT_APPLICABLE_RECORDING,
    "This promo code is not valid for recording bookings.",
  ],
  [THROTTLED, "Too many requests. Please try again later."],
  [
    INVALID_FILE_LINK,
    "The file link you provided is not valid. Please ensure it is a correct and accessible URL.",
  ],
  [MUSO_AUTH_INFORMATION_NOT_FOUND, ""],
  [
    MUSO_AUTH_USER_ID_MISMATCH,
    "The Muso account you tried to log in to doesn't match the Muso account associated with your EngineEars user stored in our records. Please contact support to resolve this issue.",
  ],
  [
    MUSO_AUTH_PROFILE_ID_UNCLAIMED,
    "The Muso account you tried to log in to doesn't have access to the Muso Profile you have already linked to your EngineEars user. Please contact support to resolve this issue.",
  ],
  [
    INVALID_MUSO_API_AUTHENTICATION,
    "Invalid Muso credentials. Please log in and out and try again.",
  ],
  [
    NO_FILE_ASSOCIATED_WITH_THE_PROJECT,
    "No file has been uploaded for this session. Please ensure you uploaded at least 1 file.",
  ],
  [
    VIDEO_UPLOAD_ERROR,
    "There was an error uploading your video file, please try again.",
  ],
  [VIDEO_INCORRECT_FILE_TYPE, "The video file is of the incorrect file type."],
  [
    VIDEO_EXCEEDS_MAX_SIZE,
    "The video file exceeds the maximum file size of 100MB",
  ],
  [
    MEDIA_CONVERT_ERROR,
    "There was an error converting the video file, please try again or reach out to customer support.",
  ],
  [
    SESSION_MODIFICATION_NOT_ALLOWED,
    "You are not allowed to modify the session at the moment.",
  ],
  [
    USER_NOT_ON_PROJECT,
    "User does not have proper permissions for this action on this project.",
  ],
  [
    RECORDING_SESSION_SHARE_LINK_NOT_FOUND,
    "Recording session not found or link expired.",
  ],
  [TRANSITION_NOT_ALLOWED, ""], // This error message comes from the backend.
  [INVALID_PAYMENT_INTENT_CLIENT_SECRET, "This payment intent is invalid."],
  [
    ENGINEER_NOT_ON_ADMIN_TEAM,
    "Engineers need to be a member of your admin team to be assigned to a project.",
  ],
  [
    STUDIO_NOT_ON_ADMIN_TEAM,
    "You do not have permissions to assign this studio room to a project.",
  ],
  [
    ENGINEER_RATES_EXCEED_ORIGINAL_PRICE,
    "The assigned engineer rates exceed the transaction total amount.",
  ],
  [BUDGET_MANAGER_NOT_FOUND, "Budget manager not found."],
  [FAVORITED_ACCOUNT_NOT_FOUND, ""],
  [
    SUPPLY_CHAIN_ACCESS_DENIED,
    "User does not currently have distribution access.",
  ],
]);
