import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import { useEffect, useMemo } from "react";
import { loadEngineerServices } from "../../../store/actions/services";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GenerateBookingButton } from "../../components/GenerateBookingButton/GenerateBookingButton";

export const AdminGenerateBookingButton = () => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { services } = useAppSelector((state) => state.engineerServices);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const isStudio = useMemo(
    () => selectedProfile.studio !== undefined,
    [selectedProfile.studio],
  );

  const showGenerateBookingButton = useMemo(
    () => services.length !== 0 || isStudio,
    [isStudio, services],
  );

  useEffect(() => {
    if (!loggedInUser) return;
    const engineer = loggedInUser.engineer;
    if (!engineer) return;
    void dispatch(loadEngineerServices({ engineer_id: engineer.id }));
  }, [dispatch, loggedInUser]);

  return showGenerateBookingButton ? (
    <GenerateBookingButton
      isLoadingEngineerServices={false}
      isMobile={isMobile}
    />
  ) : null;
};
