import unidecode from "unidecode";
import { type Error } from "../../store/actions/errorStore";
import { getRootUrl } from "../../utils/utils";

interface FetchOptions {
  signal: AbortSignal;
}

export enum FETCH_STATES {
  IDLE = "IDLE",
  LOADING = "LOADING",
  LOADED = "LOADED",
  FAILED = "FAILED",
}

const generateHeaders = function (): HeadersInit {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };

  const token = localStorage.getItem("token");
  if (token !== null && token !== "") {
    if (token.startsWith('"')) {
      try {
        const parsedToken = JSON.parse(token);
        if (parsedToken) {
          headers.Authorization = `Token ${parsedToken}`;
        }
      } catch {
        // Do Nothing
      }
    } else {
      headers.Authorization = `Token ${token}`;
    }
  }
  return headers;
};

export const uploadFilePromise = async (
  file: File,
  signedUrl: string,
  progressHandler?: (progress: number) => void,
): Promise<void> => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedUrl, true);
    if (progressHandler) {
      xhr.upload.addEventListener("progress", (ev) => {
        progressHandler((ev.loaded * 100.0) / ev.total || 100);
      });
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        resolve();
      }
    };
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    const fileName = unidecode(file.name);
    xhr.setRequestHeader(
      "Content-Disposition",
      'attachment; filename="' + fileName + '"',
    );
    xhr.send(file);
  });
};

export const uploadFile = async (
  file: File,
  signedUrl: string,
  progressHandler?: (progress: number) => void,
) => {
  const xhr = new XMLHttpRequest();
  xhr.open("PUT", signedUrl, true);
  if (progressHandler) {
    xhr.upload.addEventListener("progress", (ev) => {
      progressHandler((ev.loaded * 100.0) / ev.total || 100);
    });
  }
  xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
  const fileName = unidecode(file.name);
  xhr.setRequestHeader(
    "Content-Disposition",
    'attachment; filename="' + fileName + '"',
  );
  xhr.send(file);
};

export const uploadFilePart = async (
  blob: Blob,
  signedUrl: string,
  progressHandler?: (progress: number) => void,
): Promise<string> => {
  return new Promise((resolve) => {
    const request = new XMLHttpRequest();
    request.open("PUT", signedUrl, true);

    request.onreadystatechange = () => {
      if (
        request.readyState === XMLHttpRequest.DONE &&
        request.status === 200
      ) {
        const etag = request.getResponseHeader("etag");
        resolve(etag!);
      }
    };

    if (progressHandler) {
      request.upload.addEventListener("progress", (ev) => {
        progressHandler(ev.loaded);
      });
    }
    request.send(blob);
  });
};

/**
 * use makeBackendGetCallWithJsonResponse instead,
 * unless there's a need to access non-json response information
 */
export const makeBackendGetCall = async function (
  route: string,
  urlParams: string,
  fetchOptions?: FetchOptions,
): Promise<Response> {
  const headers = generateHeaders();
  const response = await fetch(getRootUrl() + route + urlParams, {
    headers,
    method: "GET",
    ...fetchOptions,
  });

  if (response.status >= 500) {
    throw response;
  }
  return response;
};

// use makeBackendPostCallWithJsonResponse instead,
// unless there's a need to access non-json response information
export const makeBackendPostCall = async function (
  route: string,
  data: object,
  fetchOptions?: FetchOptions,
): Promise<Response> {
  const headers = generateHeaders();
  const body = JSON.stringify(data);
  const response = await fetch(getRootUrl() + route, {
    headers,
    body,
    method: "POST",
    ...fetchOptions,
  });

  if (response.status >= 500) {
    throw response;
  }
  return response;
};

interface BackendCallWithJsonSuccessResponse<T> {
  success: true;
  resultJson: T;
  statusCode: number;
}

interface BackendCallWithJsonErrorResponse {
  success: false;
  resultJson: Error;
  statusCode: number;
}

export const makeBackendGetCallWithJsonResponse = async <T>(
  route: string,
  urlParams: string,
  fetchOptions?: FetchOptions,
): Promise<
  BackendCallWithJsonSuccessResponse<T> | BackendCallWithJsonErrorResponse
> => {
  const response = await makeBackendGetCall(route, urlParams, fetchOptions);

  if (response.ok) {
    return {
      statusCode: response.status,
      success: true,
      resultJson: (await response.json()) as T,
    };
  }

  return {
    statusCode: response.status,
    success: false,
    resultJson: (await response.json()) as Error,
  };
};

export const makeBackendPostCallWithJsonResponse = async <T>(
  route: string,
  data: object,
  fetchOptions?: FetchOptions,
): Promise<
  BackendCallWithJsonSuccessResponse<T> | BackendCallWithJsonErrorResponse
> => {
  const response = await makeBackendPostCall(route, data, fetchOptions);

  if (response.ok) {
    return {
      success: true,
      resultJson: (await response.json()) as T,
      statusCode: response.status,
    };
  }

  return {
    success: false,
    resultJson: (await response.json()) as Error,
    statusCode: response.status,
  };
};

export const readStream = async (
  route: string,
  progressHandler?: (progress: number) => void,
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    try {
      fetch(route, {
        method: "GET",
      })
        .then(async (response) => {
          if (response.status != 200) {
            const errorJson = (await response.json()) as Error;
            reject(errorJson);
            return;
          }
          const reader: ReadableStreamDefaultReader<Uint8Array> | undefined =
            response.body?.getReader();
          if (!reader) throw response;

          const contentLength = response.headers.get("Content-Length");
          const contentType = response.headers.get("Content-Type");
          if (contentLength === null) throw response;
          const contentSize = +contentLength;
          let receivedLength = 0;
          const chunks = [];
          // eslint-disable-next-line no-constant-condition
          while (true) {
            const { done, value } = await reader.read();
            if (!value) break;
            if (done) break;
            chunks.push(value);
            receivedLength += value.length;
            if (progressHandler) progressHandler(receivedLength / contentSize);
          }
          resolve(
            new Blob(chunks, {
              type: contentType ?? undefined,
            }),
          );
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};
