import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { MixMasterOrderSummary } from "../../../store/actions/mixMasterCartsStore";
import { RecordingOrderSummary } from "../../../store/actions/recordingCartsStore";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { ColorPalette } from "../../theme";
import {
  BillingInfoVerificationView,
  BudgetManagerModalBoldP,
  BudgetManagerModalP,
  BudgetManagerRow,
} from "../BudgetManagerModals/BudgetManagerModals.styles";
import { OrderBreakdown } from "../OrderBreakdown/OrderBreakdown";
import { BillingInfoParamsMap } from "./BillingInfoModals";
import {
  BillingInfoInput,
  BillingInfoInputContainer,
  RequestBudgetApprovalModalBodyContainer,
  RequestBudgetApprovalModalText,
  SuccessTextContainer,
} from "./BillingInfoModals.styles";
import { PurchaseOrderBillingInfoSteps } from "./utils";

interface BillingInfoModalBodyProps {
  step: PurchaseOrderBillingInfoSteps;
  submittingPurchaseOrder: boolean;
  projectAdminEmails: string[];
  billingInfoParams: BillingInfoParamsMap;
  recordingOrderSummaryIsLoading: boolean;
  mixMasterOrderSummaryIsLoading: boolean;
  recordingOrderSummary: RecordingOrderSummary | null;
  mixMasterOrderSummary: MixMasterOrderSummary | null;
  setBillingInfoParams: Dispatch<SetStateAction<BillingInfoParamsMap>>;
}

const BillingInfoModalBody = ({
  step,
  submittingPurchaseOrder,
  projectAdminEmails,
  billingInfoParams,
  recordingOrderSummaryIsLoading,
  mixMasterOrderSummaryIsLoading,
  recordingOrderSummary,
  mixMasterOrderSummary,
  setBillingInfoParams,
}: BillingInfoModalBodyProps) => {
  const billingInfoArray = Object.values(billingInfoParams);
  if (step === PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED) {
    return (
      <>
        <SuccessTextContainer>
          <FontAwesomeIcon icon={faCheck} color={ColorPalette.Green600} />
          <RequestBudgetApprovalModalText isBold>
            {submittingPurchaseOrder
              ? `Purchase Order submitted.`
              : `Billing information submitted.`}
          </RequestBudgetApprovalModalText>
        </SuccessTextContainer>
        <RequestBudgetApprovalModalBodyContainer>
          <RequestBudgetApprovalModalText isBold>
            Project Adminstrators:
          </RequestBudgetApprovalModalText>
          {projectAdminEmails.map((email, index) => (
            <RequestBudgetApprovalModalText key={index}>
              {email}
            </RequestBudgetApprovalModalText>
          ))}
        </RequestBudgetApprovalModalBodyContainer>
        <BudgetManagerModalP>No further actions required.</BudgetManagerModalP>
      </>
    );
  }
  if (step === PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO) {
    return (
      <>
        <BudgetManagerModalP>
          {submittingPurchaseOrder
            ? "Is the following Purchase Order correct?"
            : "Is the following billing information correct?"}
        </BudgetManagerModalP>
        {submittingPurchaseOrder
          ? billingInfoArray.map((billingInfoParam) => (
              <BillingInfoVerificationView
                key={billingInfoParam.purchase_order_id}
              >
                <Text bold>
                  {billingInfoParam.eventually_owed_to}:{" "}
                  {PennyDollarFormatter().format(billingInfoParam.total)}
                </Text>
                <BudgetManagerRow>
                  <BudgetManagerModalP>Purchase Order:</BudgetManagerModalP>
                  <BudgetManagerModalBoldP>
                    {billingInfoParam.order_number}
                  </BudgetManagerModalBoldP>
                </BudgetManagerRow>
              </BillingInfoVerificationView>
            ))
          : billingInfoArray.map((billingInfoParam) => (
              <BillingInfoVerificationView
                key={billingInfoParam.purchase_order_id}
              >
                <Text bold>
                  {billingInfoParam.eventually_owed_to}:{" "}
                  {PennyDollarFormatter().format(billingInfoParam.total)}
                </Text>
                <BudgetManagerRow>
                  <BudgetManagerModalP>Cost Center:</BudgetManagerModalP>
                  <BudgetManagerModalBoldP>
                    {billingInfoParam.cost_center}
                  </BudgetManagerModalBoldP>
                </BudgetManagerRow>
                <BudgetManagerRow>
                  <BudgetManagerModalP>
                    Work Breakdown Structure:
                  </BudgetManagerModalP>
                  <BudgetManagerModalBoldP>
                    {billingInfoParam.work_breakdown_structure}
                  </BudgetManagerModalBoldP>
                </BudgetManagerRow>
                <BudgetManagerRow>
                  <BudgetManagerModalP>General Ledger:</BudgetManagerModalP>
                  <BudgetManagerModalBoldP>
                    {billingInfoParam.general_ledger}
                  </BudgetManagerModalBoldP>
                </BudgetManagerRow>
              </BillingInfoVerificationView>
            ))}
      </>
    );
  }

  return (
    <>
      <BudgetManagerModalBoldP>
        {submittingPurchaseOrder
          ? "Please review the project price below and submit purchase order."
          : "Please review the project price below and input billing information below."}
      </BudgetManagerModalBoldP>
      <OrderBreakdown
        showLabelToggle={false}
        isLoading={
          recordingOrderSummaryIsLoading || mixMasterOrderSummaryIsLoading
        }
        showHeader={false}
        orderSummary={recordingOrderSummary ?? mixMasterOrderSummary}
      />
      <BudgetManagerModalP>
        {submittingPurchaseOrder
          ? "Input purchase order below:"
          : "Input direct billing information below:"}
      </BudgetManagerModalP>
      {submittingPurchaseOrder
        ? billingInfoArray.map((billingInfoParam) => {
            const purchaseOrderId = billingInfoParam.purchase_order_id;
            return (
              <Box key={purchaseOrderId} sx={{ width: "100%" }}>
                <Box sx={{ marginBottom: 1, marginTop: 1 }}>
                  <Text bold>
                    {billingInfoParam.eventually_owed_to}:{" "}
                    {PennyDollarFormatter().format(billingInfoParam.total)}
                  </Text>
                </Box>
                <BillingInfoInputContainer>
                  <BillingInfoInput
                    placeholder={"Purchase order"}
                    value={billingInfoParam.order_number}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setBillingInfoParams((currBillingInfo) => ({
                        ...currBillingInfo,
                        [purchaseOrderId]: {
                          ...currBillingInfo[purchaseOrderId],
                          order_number: e.target.value,
                        },
                      }));
                    }}
                  />
                </BillingInfoInputContainer>
              </Box>
            );
          })
        : billingInfoArray.map((billingInfoParam) => {
            const purchaseOrderId = billingInfoParam.purchase_order_id;
            return (
              <Box key={purchaseOrderId} sx={{ width: "100%" }}>
                <Box sx={{ marginBottom: 1, marginTop: 1 }}>
                  <Text bold>
                    {billingInfoParam.eventually_owed_to}:{" "}
                    {PennyDollarFormatter().format(billingInfoParam.total)}
                  </Text>
                </Box>
                <BillingInfoInputContainer>
                  <BillingInfoInput
                    placeholder="Cost center"
                    value={billingInfoParam.cost_center}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setBillingInfoParams((currBillingInfo) => ({
                        ...currBillingInfo,
                        [purchaseOrderId]: {
                          ...currBillingInfo[purchaseOrderId],
                          cost_center: e.target.value,
                        },
                      }));
                    }}
                  />
                  <BillingInfoInput
                    placeholder="Work Breakdown Structure"
                    value={billingInfoParam.work_breakdown_structure}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setBillingInfoParams((currBillingInfo) => ({
                        ...currBillingInfo,
                        [purchaseOrderId]: {
                          ...currBillingInfo[purchaseOrderId],
                          work_breakdown_structure: e.target.value,
                        },
                      }));
                    }}
                  />
                  <BillingInfoInput
                    placeholder="General Ledger"
                    value={billingInfoParam.general_ledger}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setBillingInfoParams((currBillingInfo) => ({
                        ...currBillingInfo,
                        [purchaseOrderId]: {
                          ...currBillingInfo[purchaseOrderId],
                          general_ledger: e.target.value,
                        },
                      }));
                    }}
                  />
                </BillingInfoInputContainer>
              </Box>
            );
          })}
    </>
  );
};

export default BillingInfoModalBody;
